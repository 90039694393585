<template>
  <div class="deals">
    <h1>Our Deals</h1>
    <p class="desc">
      If you happen to fall into one of these categories, you can get some
      discounts that we offer.
    </p>
    <div class="deals_Section">
      <DealsSection
      class="details"
        v-for="deal in deals"
        :key="deal.id"
        :dealTitle="deal.dealTitle"
        :dealdesc="deal.dealdesc"
      />
    </div>
    <div class="viewMorebtn">
      <div @click="scrollTo('navigation_bar')" class="viewMorebtn">
          <router-link to="/rooms"><button>Book Now</button></router-link>
        </div>
    </div>
  </div>
</template>

<script>
import DealsSection from "./DealsSection";
export default {
  components: {
    DealsSection,
  },
  data() {
    return {
      deals: [
        {
          dealTitle: "Deal 1",
          dealdesc:
            "This is a long description of the first deal. If someone fall in this this dela they can click the book a room button",
        },
        {
          dealTitle: "Deal 2",
          dealdesc:
            "This is a long description of the first deal. If someone fall in this this dela they can click the book a room button",
        },
        {
          dealTitle: "Deal 3",
          dealdesc:
            "This is a long description of the first deal. If someone fall in this this dela they can click the book a room button",
        },
        {
          dealTitle: "Deal 4",
          dealdesc:
            "This is a long description of the first deal. If someone fall in this this dela they can click the book a room button",
        },
      ],
    };
  },
   methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
.deals {
  padding: 80px 0px 80px 0px;
  /* height: 643.5px; */
  width: 100%;
  /* background-color: #ffff; */
  color: #fff;
  display: grid;
  place-items: center;
}
.deals_Section{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.details{
  margin: 20px;
}
a{
  color:#fff;
}
.deals h1 {
  color: #006233;
  font-size: 44px;
  line-height: 55px;
  text-align: center;
}
.deals p.desc {
  max-width: 1030px;
  color: #333;
  font-family: Raleway;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.viewMorebtn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
button {
  padding: 0px 20px 0px 20px;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  background-color: #006233;
  box-sizing: border-box;
  height: 72px;
  border-radius: 8px;
  border: none;
}
@media screen and (max-width:720px){
  
}
</style>
<template>
  <div class="dealsSection">
    <h2>{{dealTitle}}</h2>
    <p>
      {{dealdesc}}
    </p>
  </div>
</template>

<script>
export default {
    props:{
       dealTitle: String,
       dealdesc: String,

    }
};
</script>

<style scoped>
.dealsSection {
  padding: 20px;
  box-sizing: border-box;
  /* height: 248px; */
  max-width: 505px;
  border-radius: 8px;
  border: 2px solid #808080;
}
.dealsSection h2 {
  color: #006233;
  font-family: Courgette;
  font-size: 28px;
  line-height: 35px;
}
.dealsSection p {
  color: #5a6275;
  font-family: Raleway;
  font-size: 28px;
  line-height: 28px;
}
.viewMorebtn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
button {
  padding: 0px 20px 0px 20px;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  background-color: transparent;
  box-sizing: border-box;
  height: 72px;
  /* width: 181px; */
  border-radius: 8px;
  border: 2px solid #fff;
}
/* @media screen and (max-width:720px){
  .dealsSection {
  height: inherit;
} 
}*/
</style>
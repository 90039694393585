<template>
  <div id="contact">
    <h1>Contact Us</h1>
    <p class="desc">
      We are located in Sombetini, Arusha in North Tanzania and can be found
      easily via Google Maps. We are approx. 50 mins from Kilimanjaro Airport by
      car, 15 mins from Arusha Airport and a 2 hour drive to access the safari
      circuit.
    </p>
    <div class="contact">
      <div class="form">
        <form action="">
          <label for="" id="info"></label>
          <table>
            <tr>
              <td>
                <label for="">Name</label> <br />
                <input type="text" placeholder="John Smith" id="name" />
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <label for="">Email</label> <br />
                <input type="emain" placeholder="example@email.com" id="email" />
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <label for="">Phone</label> <br />
                <input  oninput="this.value = this.value.replace(/[^0-9+]/g, '').replace(/(\..*?)\..*/g, '$1');" type="text" placeholder="+255 (0) 000 000 000" id="phone" />
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <label for="Message">Message</label> <br />
                <textarea
                  name=""
                  placeholder="I would like to volunteer  for..."
                 id="message"></textarea>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <div class="viewMorebtn">
                  <button @click="submit()">Submit</button>
                </div>
              </td>
            </tr>
          </table>
        </form>
      </div>
      <div class="map">
        <div class="mapdetails">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newLine: "%0D%0A",
      userEmail: "Email",
      userName: "Name",
      phoneNumber: "Phone Number",
      userMessage: "Message",
    };
  },
  methods: {
    submit() {
      const firstname = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const phone = document.getElementById("phone").value;
      const msg = document.getElementById("message").value;
    console.log("Clicked")
      if (email == "" || firstname == "" || phone == "" || msg == "") {
        document.getElementById("info").innerHTML =
              "Please Fill the Required Fields";
            document.getElementById("info").style.borderRadius = "3px";
      } else {
        const body =
          this.userEmail +
          ":" +
          " " +
          email +

          this.newLine +
          this.userName +
          ":" +
          " " +
          firstname +

          this.newLine +
          this.phoneNumber +
          ":" +
          " " +
          phone +

          this.newLine +
          this.userMessage +
          ":" +
          " " +
          msg +
          this.newLine;

        console.log(body);

        if (body) {
          window.open(
            "mailto:info@greenliving.org?subject=GreenHouse client&body=" + body
          );
        }
      }
    },
  },
};
</script>

<style scoped>
#info {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
#contact {
  background-color: #006233;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: Raleway;
}
.contact {
  margin-top: 50px;
  width: 100%;
  display: grid;
  place-items: center;
}
.map,
.form {
  width: 100%;
}
label{
  font-size: 28px;
  line-height: 28px;
  font-weight: 300;
}
  .viewMorebtn {
    margin-top: 20px;
    display: flex;
  }
button {
  padding: 0px 20px 0px 20px;
  font-family: Courgette;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  background-color: transparent;
  box-sizing: border-box;
  height: 62px;
  /* width: 181px; */
  border-radius: 8px;
  border: 2px solid #fff;
}
.socialmedia a {
  margin: 20px;
  /* background-color: lawngreen; */
}
a {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
}
h1 {
  color: #fff;
  font-family: Courgette;
  font-size: 44px;
  line-height: 55px;
  text-align: center;
}
p.desc {
  max-width: 1030px;
  margin: auto;
  color: #fff;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
}
p {
  color: #fff;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
img {
  height: 32px;
}
.contact {
  display: flex;
}

.mapdetails{
  border-radius: 15px;
  background-color: aquamarine;
  background-image: url("../../assets/About/map.png");
  background-position: center;
  background-repeat:no-repeat;
  /* margin: auto; */
  max-width: 500px;
  height: 550px;

}
table {
  margin: auto;
  max-width: 400px;
  width: 100%;
  color: #fff;
  text-align: left;
}
textarea {
  resize: none;
  height: 100px;
}
input,
textarea {
  width: 100%;
  background-color: #c8c8c8;
  border-radius: 4px;
  padding: 5px;
  border: none;
  color: #006233;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
input {
  height: 52px;
}
@media screen and (max-width: 720px) {
  .contact {
    display: block;
  }
  label{
  font-size: 20px;
}
  p.desc {
  font-size: 20px;
  padding: 10px;
}
  button {
    width: 100%;
  }
  table {
    width: 90%;
  }
  a {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
  .mapdetails{
    margin-top: 50px;
  }
}
</style>
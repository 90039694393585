<template>
  <div class="rootes">
    <h1>Our Rootes</h1>
    <p>
      Run by non-profit organisation The Green Living Planet (GLP), The
      Greenhouse is a family business run by local Tanzanians. Some of the
      proceeds from the hostel also help to support GLP's projects, working with
      people in the local community to improve and preserve the natural
      environment.
    </p>
    <div class="viewMorebtn">
      <a href="https://www.greenlivingplanet.org/" target="_blank">
      <button>Visit The Green Living Planet</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.rootes {
  padding: 80px 0px 80px 0px;
  /* height: 643.5px; */
  width: 100%;
  background-color: #006233;
  color: #fff;
  display: grid;
  place-items: center;
}
.rootes h1 {
  font-size: 44px;
  line-height: 55px;
  text-align: center;
}
.rootes p {
  max-width: 1030px;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
}
.viewMorebtn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
button {
  padding: 0px 20px 0px 20px;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  background-color: transparent;
  box-sizing: border-box;
  height: 72px;
  /* width: 181px; */
  border-radius: 8px;
  border: 2px solid #fff;
}
a{
  color: #fff;
}
@media screen and (max-width:720px){
  .rootes h1 {
  font-size: 44px;
  line-height: 55px;
  text-align: center;
  margin-bottom: 20px;
}
.rootes p {
  font-size: 26px;
  line-height: 30px;
  padding: 10px;
  text-align: center;
}
button {
  font-size: 20px;
}
}
</style>
<template>
  <Header />
  <div>
    <Hero
      :header="hero.header"
      :h1Style="hero.h1Style"
      :backgroundStyle="hero.backgroundStyle"
    />
    <Rootes />
    <Deals />
    <div class="contacts">
      <h1></h1>
      <ContactsDetails />
    </div>
  </div>
  <Footer />
</template>

<script>
import Hero from "../components/Hero.vue";
import Header from "../components/layouts/Header";
import Rootes from "../components/About/Rootes";
import Deals from "../components/About/Deals";
import ContactsDetails from "../components/About/ContactsDetails";
import Footer from "../components/layouts/Footer";
export default {
  components: {
    Hero,
    Header,
    Rootes,
    ContactsDetails,
    Deals,
    Footer,
  },
  data() {
    return {
      hero: {
        header: "About Us",
        backgroundStyle: {
          backgroundImage: `url(${require("../assets/About/about_bg.png")})`,
        },
        h1Style: {
          // width: "500px",
          color: "#333",
          fontSize: "60px",
          // textAlign: "right",
        },
      },
    };
  },
};
</script>

<style scoped>
</style>